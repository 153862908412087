﻿function ctor(self, other, options) {
    options = Object.assign({
        'DateFields': [],
        'NumberFields': [],
        'Relations': {},
        'Collections': {},
    }, options);

    if (!Array.isArray(options.DateFields)) {
        options.DateFields = [];
    }
    if (!Array.isArray(options.Numberfields)) {
        options.Numberfields = [];
    }
    if (typeof options.Relations != "object") {
        options.Relations = {};
    }
    if (typeof options.Collections != "object") {
        options.Collections = {};
    }

    if (other != null) {
        Object.keys(other).forEach(k => {
            if (k in self) {
                if (other[k] != null) {
                    self[k] = other[k];
                    if (options.DateFields.includes(k)) {
                        if (self[k] != null) {
                            self[k] = new Date(self[k]);
                        }
                    } else if (options.NumberFields.includes(k)) {
                        if (self[k] != null) {
                            self[k] = Number(self[k]);
                        }
                    } else if (k in options.Relations) {
                        self[k] = new options.Relations[k](self[k]);
                    } else if (k in options.Collections && Array.isArray(self[k])) {
                        let elementType = options.Collections[k];
                        if (elementType != null) {
                            self[k] = self[k].map(e => e instanceof elementType ? e : new elementType(e));
                        }
                    }
                }
                if (k in options.Collections && !Array.isArray(self[k])) {
                    self[k] = [];
                }
            }
        });
    }
}
